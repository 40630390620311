import { Buffer } from 'buffer';
import './App.css';

function PowerShell() {
    function decodePowerShell() {
        document.getElementById("result").value = new TextDecoder().decode(new Buffer(document.getElementById("result").value, 'base64'));
    }

    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <h2>PowerShell Base64 Decode</h2>
                    <button className="submit" onClick={decodePowerShell}>Convert to Code</button>
                    <textarea id="result" placeholder="Base64 PowerShell Command"></textarea>
                </div>
                <p>Created by Jayson Fong</p>     
                <p><a className="App-link" href="https://email-checker.jaysonfong.org/" target="_blank" rel="noreferrer">Email Checker</a> | <a className="App-link" href="#Private-Service">Security Searchboard</a></p>
            </header>
        </div>
    );
}

export default PowerShell;